/* eslint-disable jsx-a11y/alt-text */

// Created By Jake Rase on 8/06/2024

import './App.css';
import GitHubRepos from './GitHubRepos';
import { ToolTip } from 'notion-components-react'

function App() {

  
  // Single-Use Component for Appliation Status Warning
  const alert = (message) => {
    return (
      <>
      <div className="alert-main">
          {message}
      </div>
    </>
    )
  }

  // Gray Circle Link Component
  const link = (url, text, imgaddr) => {
    return (
      <>
        <a href={url} className="page-link-main" >
          <img src={imgaddr} className="page-link-img" />
        </a>
      </>
    )
  }

  return (
    <>
    {alert(
        <>
        <p className="alert-text">
          This Website is Mostly for Application Processes, Not You?
        </p>
        <a href="https://jakerase.dev" className="alert-external" >
          <i class="bi bi-box-arrow-up-right"></i>
        </a>
        </>
    )}
      <div className="page-main">
        <img src="./Images/Logo.png" className="page-logo-image" />
        <h1 className="page-name">
          Jake Rase
        </h1>
        <p className="page-subtitle">
          Somewhat responsible software developer.....
        </p>
        <div className="page-link-containers">
          <div style={{width: '3.25rem'}}>
            <ToolTip
                label="GitHub Repository"
                mode="light"
                delay="1.5"
            >
              {link("https://github.com/definiteconfusion", "Github", "./Images/Github.png")}
            </ToolTip>
          </div>
          <div style={{width: '3.25rem'}}>
            <ToolTip
                label="LinkedIn Profile"
                mode="light"
                delay="1.5"
            >
              {link("https://www.linkedin.com/in/jake-rase-9a28a926a/", "Linkedin", "./Images/Linkedin.png")}
            </ToolTip>
          </div>
          <div style={{width: '3.25rem'}}>
            <ToolTip
                label="Twitter Profile"
                mode="light"
                delay="1.5"
            >
              {link("https://x.com/djakerase", "Twitter", "./Images/Twitter.png")}
            </ToolTip>
          </div>
          <div style={{width: '3.25rem'}}>
            <ToolTip
                label="Send Email"
                mode="light"
                delay="1.5"
            >
              {link("mailto:jake@jakerase.dev", "Email", "./Images/Mail.png")}
            </ToolTip>
          </div>
        </div>
        <h3 className="page-area-label">About Me</h3>
        <div className="page-description">
          <p className="page-description-text">
          Hey... I'm Jake. I'm a full stack web developer, even though I prefer backends, I use React, Supabase, and Django for my stuff. Prototyping and initial design is done with Figma. This website is built as a simple easy to load webpage, it only uses two APIs, the GitHub API and Bootstrap Icons, oher than that it uses local fonts and only seven total images!
          </p>
        </div>
        <h3 className="page-area-label">Stuff I've Done...</h3>
        <GitHubRepos username="definiteconfusion" />
      </div>
    </>
  );
}

export default App;
