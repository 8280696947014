/* eslint-disable jsx-a11y/alt-text */

// Created By Jake Rase on 8/06/2024


import React, { useEffect, useState } from 'react';
import { ToolTip } from 'notion-components-react'
import './App.css';

let languages = {
    "Python": "py",
    "JavaScript": "js",
    "HTML": "html",
    "CSS": "css",
    "TypeScript": "ts",
    "Shell": "sh",
    "Java": "java",
    "C++": "cpp",
    "C": "c",
    "Ruby": "rb",
    "PHP": "php",
    "Go": "go",
    "Swift": "txt",
    "Objective-C": "m",
    "Kotlin": "kt",
    "Rust": "rs",
    "Scala": "scala",
    "R": "r",
    "Perl": "pl",
    null: "txt"
}

// Pre-existing project constant
const project = (title, desc, gitLink, lang, langLink, branch) => {

    // List of available bootstrap filetype icons
    const bootstrapFileTypes = ['py', 'js', 'html', 'css', 'ts', 'java', 'cpp', 'cs'];
    
    return (
      <div className="project-main">
      <div className="project-innerlay">
        <div className="project-infolay">
        <h2 className="project-info-title">
          {title}
        </h2>
        <p className="project-info-subtitle">
          {desc}
        </p>
        <div className="project-git-container">
          <img src="./Images/Git.png" className="project-git-img" />
          <p className="project-git-text">
          {branch}
          </p>
        </div>
        <div className="project-info-links">
          <div style={{width: '3rem'}}>
            <ToolTip
            	label="GitHub Repository"
	            mode="light"
              delay="1.5"
            >
              {link(gitLink, "Github", "./Images/Github.png")}
            </ToolTip>
          </div>
          <div style={{width: '3rem'}}>
            <ToolTip
              label="Active Language"
              mode="light"
              delay="1.5"
            >
              <div className="page-link-main">
                <i className={`bi ${bootstrapFileTypes.includes(languages[lang]) ? 
                  `bi-filetype-${languages[lang]}` : 
                  'bi-file-earmark'}`}>
                </i>
              </div>
            </ToolTip>
          </div>
          <ToolTip
          	label="Copy Command"
	          mode="light"
            delay="1.5"
          >
            <div onClick={() => navigator.clipboard.writeText(`git clone ${gitLink}.git`)}>
            <div className="page-link-main" >
              <i className="bi bi-clipboard"></i>
            </div>
            </div>
          </ToolTip>
        </div>
        </div>
      </div>
      </div>
    )
  }

  const link = (url, text, imgaddr) => {
    return (
      <>
        <a href={url} className="page-link-main" >
          <img src={imgaddr} className="page-link-img" />
        </a>
      </>
    )
  }

// Function to fetch and render GitHub repositories
const GitHubRepos = ({ username }) => {
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch(`https://api.github.com/users/${username}/repos`);
        const data = await response.json();
        setRepos(data);
      } catch (error) {
        console.error('Error fetching the repos:', error);
      }
    };

    fetchRepos();
  }, [username]);

  return (
    <div>
      {repos.map(repo => {
        const {
          name,
          description,
          html_url,
          language,
          default_branch
        } = repo;
        
        // Only render if description contains "%ms%"
        if (description && description.includes("%ms%")) {
          const langLink = language ? `https://github.com/trending/${language.toLowerCase()}?since=daily` : '#';
          return (
            <div key={repo.id}>
              {project(name, description, html_url, language, langLink, default_branch)}
            </div>
          );
        }
        return null; // Skip rendering if condition not met
      })}
    </div>
  );
};

export default GitHubRepos;
